import { Routes, Route, Navigate } from 'react-router-dom';
const AddProject = React.lazy(() =>
  import('pages/AddProject/AddProject.react'),
);
const AddVendor = React.lazy(() => import('pages/AddVendor/AddVendor.react'));
const ProjectDetails = React.lazy(() =>
  import('pages/ProjectDetails/ProjectDetails.react'),
);
const StakeholderProfile = React.lazy(() =>
  import('pages/StakeholderProfile/StakeholderProfile.react'),
);
const AddPurchaseTransaction = React.lazy(() =>
  import('pages/AddPurchaseTransaction/AddPurchaseTransaction.react'),
);
const AddSaleTransaction = React.lazy(() =>
  import('pages/AddSaleTransaction/AddSaleTransaction.react'),
);
const ApplicationAuditTrail = React.lazy(() =>
  import('pages/ApplicationAuditTrail/ApplicationAuditTrail.react'),
);
import { TabsProvider } from '../contextAPI';
import React, { Suspense, useContext, useEffect, useState } from 'react';
const DataInput = React.lazy(() => import('pages/DataInput/DataInput.react'));
const Users = React.lazy(() => import('pages/Users/Users.react'));
const TermsOfUse = React.lazy(() =>
  import('pages/TermsOfUse/TermsOfUse.react'),
);
const AddOriginationProject = React.lazy(() =>
  import('pages/AddOriginationProject/AddOriginationProject.react'),
);
import { EditPurchaseTransactionFormDirtyProvider } from 'containers/EditPurchaseTransactionFormDirty.react';
const OrgProject = React.lazy(() =>
  import('pages/Origination/Org_Project/Org_Projects'),
);
const EditOriginationProject = React.lazy(() =>
  import(
    'pages/Origination/EditOriginationProject/EditOriginationProject.react'
  ),
);
const SettingsPage = React.lazy(() =>
  import('pages/SettingsPage/SettingsPage.react'),
);
const UserList = React.lazy(() =>
  import('pages/UserManagement/UserList.react'),
);
const GenerateOriginationPDFReport = React.lazy(() =>
  import(
    'pages/GenerateOriginationPDFReport/GenerateOriginationPDFReport.react'
  ),
);
const Layout = React.lazy(() => import('layout/Layout.react'));
const InsetPage = React.lazy(() =>
  import('pages/InsetsPage/SettingsPage.react'),
);
const PortfolioOverview = React.lazy(() =>
  import('pages/PortfolioOverview/PortfolioOverview.react'),
);
const Projects = React.lazy(() => import('pages/Projects/Projects.react'));
const TransactionListPage = React.lazy(() =>
  import('pages/Transactions/TransactionPage.react'),
);
const StakeholdersProfile = React.lazy(() =>
  import('pages/StakeholdersProfile/StakeholdersProfile.react'),
);
const NewApplicationAuditLogComp = React.lazy(() =>
  import(
    'components/NewApplicationAuditLogComp/NewApplicationAuditLogComp.react'
  ),
);
const OriginationParticipantsList = React.lazy(() =>
  import('pages/OriginationParticipantsList/OriginationParticipantsList.react'),
);
import { pathName } from './routes.const';
const OrgProjectCalculationsReport = React.lazy(() =>
  import(
    'pages/Origination/Org_Project/Org_ProjectCalculationsReport/Org_ProjectCalculationsReport.react'
  ),
);
const ParticipantTab = React.lazy(() =>
  import('pages/Origination/Org_Project/Participants/ParticipantTab.react'),
);
import { ParticipantInfoProvider } from 'contextAPI/participantInfoContext';
const ParticipantDataCollection = React.lazy(() =>
  import('pages/ParticipantDataCollection/ParticipantDataCollection.react'),
);
const LandingPage = React.lazy(() =>
  import('pages/ParticipantDataCollection/pages/LandingPage.react'),
);

const MenuPage = React.lazy(() =>
  import('pages/ParticipantDataCollection/pages/MenuPage.react'),
);
import ProtectedRoute from './ProtectedRoute.react';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import {
  ADMIN,
  ORIGINATION_PARTICIPANT_READ,
  ORIGINATION_MANAGER,
  ORIGINATION_PROJECT_CREATE,
  ORIGINATION_PROJECT_READ,
  ORIGINATION_PROJECT_UPDATE,
  ORIGINATION_VIEWER,
  PARTICIPANT,
  PARTICIPANT_CONTRIBUTOR,
  PARTICIPANT_MANAGER,
} from 'utils/config';
const BaseLineReportingMainPage = React.lazy(() =>
  import(
    'pages/ParticipantDataCollection/BaseLineReporting/BaseLineReportingMainPage.react'
  ),
);
import { BaselineDropdownProvider } from 'contextAPI/baselineDropdownContext';
import { EnrollViaEmailContainer } from 'containers/EnrollViaMailContainer';
import { LifeCycleProvider } from 'contextAPI/statusLifecycleContext';
import { ReportOnEmailViaBaseline } from 'containers/ReportOnBaselineViaEmail';
const ActivityReporting = React.lazy(() =>
  import(
    'pages/ParticipantDataCollection/ActivityReporting/ActivityReporting.react'
  ),
);
const Reports = React.lazy(() => import('pages/ReportsPage/Reports.react'));
const Logout = React.lazy(() => import('pages/Logout/Logout.react'));
import { UnitOfMeasureListProvider } from 'contextAPI/unitOfMeasureContext';
import Loader from 'components/Loader/Loader.react';
import { updateUserLastActivity } from 'utils/helper';
const VideoTutorialsPage = React.lazy(() =>
  import('pages/VideoTutorialsPage/VideoTutorialsPage.react'),
);
const ErrorPage = React.lazy(() => import('pages/errorPage/errorPage.react'));

const Routing = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [currentOriginationTab, setCurrentOriginationTab] = useState(0);
  const { permissions, userRoles, fetchRolesAndPermissions } =
    useContext(userAuthRolesContext);

  useEffect(() => {
    updateUserLastActivity().then(() => {
      fetchRolesAndPermissions();
    });
  }, []);

  return (
    <TabsProvider
      value={{
        assets: [currentTab, setCurrentTab],
        origination: [currentOriginationTab, setCurrentOriginationTab],
      }}>
      <Suspense fallback={<Loader loading={true} zIndex={999999} />}>
        <Routes>
          <Route path="logout" exact element={<Logout />} />
          <Route exact path="error/:errorCode" element={<ErrorPage />} />
          <Route element={<Layout />}>
            <Route
              exact
              path="video-tutorials"
              element={<VideoTutorialsPage />}
            />
            <Route
              path="/"
              element={
                <ProtectedRoute
                  url="/"
                  element={<Navigate replace to="portfolio" />}
                />
              }
            />
            <Route
              path="users"
              exact
              element={<ProtectedRoute url="users" element={<Users />} />}
            />
            <Route
              path="user-list"
              exact
              element={
                <ProtectedRoute url="user-list" element={<UserList />} />
              }
            />
            <Route
              path="terms-of-use"
              exact
              element={
                <ProtectedRoute url="terms-of-use" element={<TermsOfUse />} />
              }
            />
            <Route
              path="projects"
              exact
              element={
                <ProtectedRoute
                  url="projects"
                  element={<Navigate replace to={'/portfolio'} />}
                />
              }
            />
            <Route
              path="projects/generate-report/:id"
              exact
              element={
                <ProtectedRoute
                  url="projects/generate-report/:id"
                  element={<GenerateOriginationPDFReport />}
                />
              }
            />
            <Route
              path="projects/add"
              exact
              element={
                <ProtectedRoute
                  url="projects/add"
                  userPermissions={permissions}
                  requiredPermissions={[ORIGINATION_PROJECT_CREATE]}
                  element={<AddOriginationProject />}
                />
              }
            />
            <Route
              path="projects/edit/:id"
              exact
              element={
                <ProtectedRoute
                  url="projects/edit/:id"
                  userPermissions={permissions}
                  requiredPermissions={[ORIGINATION_PROJECT_UPDATE]}
                  element={<EditOriginationProject />}
                />
              }
            />
            <Route
              path="projects/:id"
              exact
              element={
                <ProtectedRoute
                  url="projects/:id"
                  userPermissions={permissions}
                  requiredPermissions={[ORIGINATION_PROJECT_READ]}
                  element={<OrgProject />}
                />
              }
            />
            <Route
              path="projects/:id/calculations-report"
              exact
              element={
                <ProtectedRoute
                  userPermissions={permissions}
                  requiredPermissions={[ORIGINATION_PROJECT_READ]}
                  url="projects/:id/calculations-report"
                  element={<OrgProjectCalculationsReport />}
                />
              }
            />
            <Route
              path="stakeholders"
              exact
              element={
                <ProtectedRoute
                  url="stakeholders"
                  userPermissions={permissions}
                  requiredPermissions={[ORIGINATION_PARTICIPANT_READ]}
                  element={<OriginationParticipantsList />}
                />
              }
            />
            <Route
              path="stakeholders/:id"
              exact
              element={
                <ProtectedRoute
                  url="stakeholders/:id"
                  userPermissions={permissions}
                  requiredPermissions={[ORIGINATION_PARTICIPANT_READ]}
                  element={
                    <UnitOfMeasureListProvider>
                      <ParticipantTab disableVirtualization={false} />
                    </UnitOfMeasureListProvider>
                  }
                />
              }
            />
            <Route
              path="insights"
              exact
              element={
                <ProtectedRoute
                  userPermissions={permissions}
                  requiredPermissions={[ORIGINATION_PROJECT_READ]}
                  url="insights"
                  element={<>Insights</>}
                />
              }
            />
            <Route path="stakeholders">
              <Route
                path="participant-data-collection"
                exact
                element={
                  <ProtectedRoute
                    url="stakeholders/participant-data-collection"
                    userPermissions={userRoles}
                    requiredPermissions={[
                      ADMIN,
                      ORIGINATION_MANAGER,
                      PARTICIPANT,
                      PARTICIPANT_CONTRIBUTOR,
                      PARTICIPANT_MANAGER,
                    ]}
                    element={
                      <UnitOfMeasureListProvider>
                        <ParticipantInfoProvider>
                          <ParticipantDataCollection />
                        </ParticipantInfoProvider>
                      </UnitOfMeasureListProvider>
                    }
                  />
                }
              />
              <Route
                path="mail/landing-page"
                exact
                element={
                  <LifeCycleProvider>
                    <EnrollViaEmailContainer>
                      <ProtectedRoute
                        url="stakeholders/mail/landing-page"
                        userPermissions={userRoles}
                        requiredPermissions={[
                          ADMIN,
                          ORIGINATION_MANAGER,
                          PARTICIPANT,
                          PARTICIPANT_CONTRIBUTOR,
                          PARTICIPANT_MANAGER,
                        ]}
                        element={<LandingPage />}
                      />
                    </EnrollViaEmailContainer>
                  </LifeCycleProvider>
                }
              />
              <Route
                path="landing-page"
                exact
                element={
                  <ProtectedRoute
                    url="stakeholders/landing-page"
                    userPermissions={userRoles}
                    requiredPermissions={[
                      ADMIN,
                      ORIGINATION_MANAGER,
                      PARTICIPANT,
                      PARTICIPANT_CONTRIBUTOR,
                      PARTICIPANT_MANAGER,
                    ]}
                    element={<LandingPage />}
                  />
                }
              />
              <Route
                path="enrollment-menu-page"
                exact
                element={
                  <ProtectedRoute
                    url="stakeholders/enrollment-menu-page"
                    userPermissions={userRoles}
                    requiredPermissions={[
                      ADMIN,
                      ORIGINATION_MANAGER,
                      PARTICIPANT,
                      PARTICIPANT_CONTRIBUTOR,
                      PARTICIPANT_MANAGER,
                    ]}
                    element={<MenuPage moduleName="enrollment" />}
                  />
                }
              />
              <Route
                path="mail/landing-page"
                exact
                element={
                  <LifeCycleProvider>
                    <ReportOnEmailViaBaseline>
                      <ProtectedRoute
                        url="stakeholders/mail/landing-page"
                        userPermissions={userRoles}
                        requiredPermissions={[
                          ADMIN,
                          ORIGINATION_MANAGER,
                          PARTICIPANT,
                          PARTICIPANT_CONTRIBUTOR,
                          PARTICIPANT_MANAGER,
                        ]}
                        element={<LandingPage />}
                      />
                    </ReportOnEmailViaBaseline>
                  </LifeCycleProvider>
                }
              />
              <Route
                path="baseline-menu-page"
                exact
                element={
                  <ProtectedRoute
                    url="stakeholders/baseline-menu-page"
                    userPermissions={userRoles}
                    requiredPermissions={[
                      ADMIN,
                      ORIGINATION_MANAGER,
                      PARTICIPANT,
                      PARTICIPANT_CONTRIBUTOR,
                      PARTICIPANT_MANAGER,
                    ]}
                    element={<MenuPage moduleName="baseline" />}
                  />
                }
              />
              <Route
                path="activity-reporting-menu-page"
                exact
                element={
                  <ProtectedRoute
                    url="stakeholders/activity-reporting-menu-page"
                    userPermissions={userRoles}
                    requiredPermissions={[
                      ADMIN,
                      ORIGINATION_MANAGER,
                      PARTICIPANT,
                      PARTICIPANT_CONTRIBUTOR,
                      PARTICIPANT_MANAGER,
                    ]}
                    element={<MenuPage moduleName="activity" />}
                  />
                }
              />
            </Route>
            <Route path="stakeholders">
              <Route
                path="participant-field-baseline"
                exact
                element={
                  <ProtectedRoute
                    url="stakeholders/participant-field-baseline"
                    userPermissions={userRoles}
                    requiredPermissions={[
                      ADMIN,
                      ORIGINATION_MANAGER,
                      PARTICIPANT,
                      PARTICIPANT_CONTRIBUTOR,
                      PARTICIPANT_MANAGER,
                    ]}
                    element={
                      <UnitOfMeasureListProvider>
                        <BaselineDropdownProvider>
                          <BaseLineReportingMainPage />
                        </BaselineDropdownProvider>
                      </UnitOfMeasureListProvider>
                    }
                  />
                }
              />
              <Route
                path="participant-field-activity"
                exact
                element={
                  <ProtectedRoute
                    url="stakeholders/participant-field-activity"
                    userPermissions={userRoles}
                    requiredPermissions={[
                      ADMIN,
                      ORIGINATION_MANAGER,
                      PARTICIPANT,
                      PARTICIPANT_CONTRIBUTOR,
                      PARTICIPANT_MANAGER,
                    ]}
                    element={
                      <UnitOfMeasureListProvider>
                        <BaselineDropdownProvider>
                          <ActivityReporting />
                        </BaselineDropdownProvider>
                      </UnitOfMeasureListProvider>
                    }
                  />
                }
              />
            </Route>
            <Route path="assets">
              <Route index exact element={<Navigate replace to="projects" />} />
              <Route
                path="projects"
                exact
                element={
                  <ProtectedRoute
                    url="assets/projects"
                    userPermissions={userRoles}
                    requiredPermissions={[ADMIN]}
                    element={<Projects />}
                  />
                }
              />
              <Route
                path="projects/add"
                exact
                element={
                  <ProtectedRoute
                    url="assets/projects/add"
                    userPermissions={userRoles}
                    requiredPermissions={[ADMIN]}
                    element={<AddProject />}
                  />
                }
              />
              <Route
                path="projects/edit/:id"
                exact
                element={
                  <ProtectedRoute
                    url="assets/projects/edit/:id"
                    userPermissions={userRoles}
                    requiredPermissions={[ADMIN]}
                    element={<AddProject />}
                  />
                }
              />
              <Route
                path="projects/:id"
                exact
                element={
                  <ProtectedRoute
                    url="assets/projects/:id"
                    userPermissions={userRoles}
                    requiredPermissions={[ADMIN]}
                    element={<ProjectDetails />}
                  />
                }
              />
              <Route
                path="stakeholders"
                exact
                element={
                  <ProtectedRoute
                    url="assets/stakeholders"
                    userPermissions={userRoles}
                    requiredPermissions={[ADMIN]}
                    element={<StakeholdersProfile />}
                  />
                }
              />
              <Route
                path="stakeholders/add"
                exact
                element={
                  <ProtectedRoute
                    url="assets/stakeholders/add"
                    userPermissions={userRoles}
                    requiredPermissions={[ADMIN]}
                    element={<AddVendor />}
                  />
                }
              />
              <Route
                path="stakeholders/edit/:id"
                exact
                element={
                  <ProtectedRoute
                    url="assets/stakeholders/edit/:id"
                    userPermissions={userRoles}
                    requiredPermissions={[ADMIN]}
                    element={<AddVendor />}
                  />
                }
              />
              <Route
                path="stakeholders/:id"
                exact
                element={
                  <ProtectedRoute
                    url="assets/stakeholders/:id"
                    userPermissions={userRoles}
                    requiredPermissions={[ADMIN]}
                    element={
                      <StakeholderProfile disableVirtualization={false} />
                    }
                  />
                }
              />
            </Route>
            <Route
              path="transactions"
              exact
              element={
                <ProtectedRoute
                  url="transactions"
                  userPermissions={userRoles}
                  requiredPermissions={[ADMIN]}
                  element={
                    <TransactionListPage disableVirtualization={false} />
                  }
                />
              }
            />
            <Route
              path="transactions/add-purchase-transaction"
              exact
              element={
                <ProtectedRoute
                  url="transactions/add-purchase-transaction"
                  userPermissions={userRoles}
                  requiredPermissions={[ADMIN]}
                  element={
                    <EditPurchaseTransactionFormDirtyProvider>
                      <AddPurchaseTransaction />
                    </EditPurchaseTransactionFormDirtyProvider>
                  }
                />
              }
            />
            <Route
              path="transactions/purchase-transaction/edit/:id"
              exact
              element={
                <ProtectedRoute
                  url="transactions/purchase-transaction/edit/:id"
                  userPermissions={userRoles}
                  requiredPermissions={[ADMIN]}
                  element={
                    <EditPurchaseTransactionFormDirtyProvider>
                      <AddPurchaseTransaction />
                    </EditPurchaseTransactionFormDirtyProvider>
                  }
                />
              }
            />
            <Route
              path="transactions/purchase-transaction/:id"
              exact
              element={
                <ProtectedRoute
                  url="transactions/purchase-transaction/:id"
                  userPermissions={userRoles}
                  requiredPermissions={[ADMIN]}
                  element={<AddPurchaseTransaction />}
                />
              }
            />
            <Route
              path="transactions/add-sale-transaction"
              exact
              element={
                <ProtectedRoute
                  url="transactions/add-sale-transaction"
                  userPermissions={userRoles}
                  requiredPermissions={[ADMIN]}
                  element={
                    <EditPurchaseTransactionFormDirtyProvider>
                      <AddSaleTransaction />
                    </EditPurchaseTransactionFormDirtyProvider>
                  }
                />
              }
            />
            <Route
              path="transactions/sale-transaction/edit/:id"
              exact
              element={
                <ProtectedRoute
                  url="transactions/sale-transaction/edit/:id"
                  userPermissions={userRoles}
                  requiredPermissions={[ADMIN]}
                  element={
                    <EditPurchaseTransactionFormDirtyProvider>
                      <AddSaleTransaction />
                    </EditPurchaseTransactionFormDirtyProvider>
                  }
                />
              }
            />
            <Route
              path="transactions/sale-transaction/:id"
              exact
              element={
                <ProtectedRoute
                  url="transactions/sale-transaction/:id"
                  userPermissions={userRoles}
                  requiredPermissions={[ADMIN]}
                  element={<AddSaleTransaction />}
                />
              }
            />
            <Route
              path="data-library"
              exact
              element={
                <ProtectedRoute
                  url="data-library"
                  userPermissions={userRoles}
                  requiredPermissions={[ADMIN]}
                  element={<DataInput />}
                />
              }
            />
            <Route
              path="audit-log"
              exact
              element={
                <ProtectedRoute
                  userPermissions={permissions}
                  requiredPermissions={[ORIGINATION_PROJECT_READ]}
                  url="audit-log"
                  element={<NewApplicationAuditLogComp />}
                />
              }
            />
            <Route
              path="old-audit-log"
              exact
              element={
                <ProtectedRoute
                  userPermissions={permissions}
                  requiredPermissions={[ORIGINATION_PROJECT_READ]}
                  url="old-audit-log"
                  element={<ApplicationAuditTrail />}
                />
              }
            />
            <Route
              path="settings"
              exact
              element={
                <ProtectedRoute
                  url="settings"
                  userPermissions={userRoles}
                  requiredPermissions={[ADMIN]}
                  element={<SettingsPage />}
                />
              }
            />
            <Route
              path="insets"
              exact
              element={
                <ProtectedRoute
                  url="insets"
                  userPermissions={permissions}
                  requiredPermissions={[ORIGINATION_PROJECT_READ]}
                  element={<InsetPage disableVirtualization={false} />}
                />
              }
            />
            <Route
              path="portfolio"
              exact
              element={
                <ProtectedRoute
                  url="portfolio"
                  userPermissions={userRoles}
                  requiredPermissions={[
                    ADMIN,
                    ORIGINATION_VIEWER,
                    ORIGINATION_MANAGER,
                    PARTICIPANT_MANAGER,
                  ]}
                  element={<PortfolioOverview />}
                />
              }
            />
            <Route
              path={pathName.participantDataCollection}
              exact
              element={
                <ProtectedRoute
                  userPermissions={permissions}
                  requiredPermissions={[ORIGINATION_PROJECT_READ]}
                  url={pathName.participantDataCollection}
                  element={<>Participant Enrollment Page</>}
                />
              }
            />
            <Route
              path="reports"
              exact
              element={
                <ProtectedRoute
                  url="reports"
                  userPermissions={userRoles}
                  requiredPermissions={[
                    ADMIN,
                    ORIGINATION_VIEWER,
                    ORIGINATION_MANAGER,
                    PARTICIPANT_MANAGER,
                  ]}
                  element={<Reports />}
                />
              }
            />
            <Route
              path="*"
              element={
                <ProtectedRoute
                  userPermissions={permissions}
                  requiredPermissions={[ORIGINATION_PROJECT_READ]}
                  url="*"
                  element={<Navigate replace to="/portfolio" />}
                />
              }
            />
          </Route>
        </Routes>
      </Suspense>
    </TabsProvider>
  );
};
export default Routing;
