import './App.css';
import { ThemeProvider } from '@mui/material';
import theme from './theme/Theme';
import 'react-toastify/dist/ReactToastify.css';
import Routing from './Routes';
import { useStyles } from 'theme/GlobalStyles';
import '@fontsource/open-sans';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/400-italic.css';
import { MapProvider } from 'contextAPI/mapContext';
import { UserAuthRolesProvider } from 'contextAPI/userAuthRolesContext';
import { LifeCycleProvider } from 'contextAPI/statusLifecycleContext';

function App() {
  const { ToastContainer } = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <ToastContainer />
        <MapProvider>
          <UserAuthRolesProvider encodingEnabled>
            <LifeCycleProvider>
              <Routing />
            </LifeCycleProvider>
          </UserAuthRolesProvider>
        </MapProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
