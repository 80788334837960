import React, { useContext, useEffect, useState } from 'react';
import { RequestInterceptor } from './RequestInterceptor.react';
import { Backdrop, CircularProgress } from '@mui/material';
import { getAuth, getRedirectResult, onAuthStateChanged } from 'firebase/auth';
import { useFirebaseSignIn } from './SignInButton.react';
import { logoutPath } from 'layout/header/Header.content';
import { useLocation } from 'react-router';
import { SettingsContext } from 'contextAPI/settings';
import { SESSION_LOGIN } from 'urls';
import axios from 'axios';
import { isEmpty, updateUserLastActivity } from 'utils/helper';
import PropTypes from 'prop-types';

/**
 * A custom security provider for redirect to Microsoft Authentication
 */

export const SecurityProvider = ({ children }) => {
  const { app, handleLogin } = useFirebaseSignIn();
  const [isLogoutPage, setIsLogoutPage] = useState(false);

  const [user, setUser] = useState(null);
  const settings = useContext(SettingsContext);
  const auth = getAuth(app);
  const { pathname } = useLocation();

  const postIdTokenToSessionLogin = async (idToken) => {
    try {
      await axios
        .post(SESSION_LOGIN, {
          idToken,
        })
        .then(() => {
          updateUserLastActivity();
        });
    } catch (error) {
      console.error('login failed', error);
    }
  };

  const login = async () => {
    let updateUser = null;
    onAuthStateChanged(auth, (user) => {
      updateUser = user;
    });

    const result = await getRedirectResult(auth)?.catch(() => null);

    /* istanbul ignore else */
    if (isEmpty(result) && isEmpty(updateUser)) {
      if (settings.isDevEnvironment) {
        const { user } = await handleLogin();
        setUser(user);
      } else {
        await handleLogin();
      }
    } else if (!isEmpty(updateUser)) {
      setUser(updateUser);
      /* istanbul ignore else */
      if (!settings.isDevEnvironment) {
        // getting access token from firebase
        const idToken = updateUser?.accessToken;
        // sending it to backend to store cookie
        return postIdTokenToSessionLogin(idToken);
      }
    }
  };

  useEffect(() => {
    if (pathname === logoutPath) {
      setIsLogoutPage(true);
    } else {
      login();
    }
  }, [JSON.stringify(user)]);

  /* istanbul ignore else */
  if (isLogoutPage) {
    return <>{children}</>;
  }

  if (user == null) {
    return (
      <RequestInterceptor>
        <Backdrop
          open={true}
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress color="success" />
        </Backdrop>
      </RequestInterceptor>
    );
  } else {
    return <RequestInterceptor>{children}</RequestInterceptor>;
  }
};

SecurityProvider.propTypes = {
  children: PropTypes.node,
};
